import type { Option } from '@design-system/components/Inputs/InputsSelect.props'
import { countryPrefixes } from '@design-system/data/countryPrefixes'

export type BookAnAppointmentForm = {
  country: string
  city: string
  shop: string
  firstName: string
  lastName: string
  email: string
  phonePrefix: string
  phoneNumber: string
  message: string
}

export const useBookAnAppointment = async (brand?: string) => {
  const { country } = useRouteHelper()
  const route = useRoute()
  const { currentWebsite } = useWebsite()
  const { language } = useRouteHelper()
  const { customer } = await useCustomer()
  const { fetchBookAppontment } = await useBookAndReserve()
  const bookAnAppointmentForm = ref<BookAnAppointmentForm>({
    country: '',
    city: '',
    shop: '',
    firstName: customer?.value?.firstName ?? '',
    lastName: customer?.value?.lastName ?? '',
    email: customer?.value?.email ?? '',
    phonePrefix: customer?.value?.prefix ?? '',
    phoneNumber: customer?.value?.phoneNumber ?? '',
    message: '',
  })
  const citiesOptions = ref<Option[]>([])
  const shopsOptions = ref<Option[]>([])
  const queryParamLoaded = ref<boolean>(false)

  const brandCountry = computed(() => {
    switch (brand ?? currentWebsite.value) {
      case 'giorgio-armani':
        return ['giorgio-armani', 'giorgioarmani']
      case 'emporio-armani':
        return ['emporio-armani', 'emporioarmani']
      case 'ea7':
        return ['ea7']
      case 'armani-exchange':
        return ['ax-armani-exchange', 'ax-armaniexchange', 'axarmaniexchange']
      case 'armani-casa':
        return ['armani-casa', 'armani/casa']

      default:
        return ''
    }
  })

  const brandShop = computed(() => {
    switch (brand ?? currentWebsite.value) {
      case 'giorgio-armani':
        return 'GIORGIO_ARMANI'
      case 'emporio-armani':
        return 'EMPORIO_ARMANI'
      case 'ea7':
        return 'EA7'
      case 'armani-exchange':
        return 'ARMANI_EXCHANGE'
      case 'armani-casa':
        return 'ARMANI_CASA'

      default:
        return ''
    }
  })

  const version = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}${month}${day}`
  }

  const { data: countriesOptions } = await useFetch(
    '/api/yext/getBookAnAppointmentCountries',
    {
      query: {
        v: version(),
        name: brandCountry,
        locale: language,
      },
      transform(data) {
        if (data) {
          return data
            .map(country => ({
              label: country.c_addressCountryDisplayName,
              value: country.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
        } else {
          return []
        }
      },
    }
  )

  const bookAnAppointmentSelectedCountry = computed(
    () => bookAnAppointmentForm.value.country
  )

  const bookAnAppointmentShopsQuery = computed(() => ({
    v: version(),
    c_folderBrand: brandShop.value,
    countryCode: bookAnAppointmentSelectedCountry.value,
    locale: language,
  }))

  const { data: shopsFromCurrentState } = await useFetch(
    '/api/yext/getBookAnAppointmentShops',
    {
      query: bookAnAppointmentShopsQuery,
      immediate: false,
      transform(data) {
        if (data) {
          const mapWithoutDuplicates = new Map<string, Option>()
          data.forEach(shop => {
            mapWithoutDuplicates.set(shop.address.city, {
              label: shop.address.city,
              value: shop.address.city,
            })
          })
          citiesOptions.value = [...mapWithoutDuplicates.values()].sort(
            (a, b) => a.label.localeCompare(b.label)
          )

          return data
        } else {
          citiesOptions.value = []

          return []
        }
      },
    }
  )

  const prefixes = countryPrefixes
    .map(country => ({
      label: `${country?.code} ${country?.dial_code}`,
      value: `${country?.dial_code}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const handleSubmitBookAnAppointment = async () => {
    try {
      const store = shopsFromCurrentState.value!.find(
        shop => shop.id === bookAnAppointmentForm.value.shop
      )
      fetchBookAppontment(store!, bookAnAppointmentForm.value)
    } catch (error) {
      console.error(error)
    }
  }

  watch(bookAnAppointmentSelectedCountry, () => {
    bookAnAppointmentForm.value.city = ''
    bookAnAppointmentForm.value.shop = ''
  })

  watch(shopsFromCurrentState, newShopsFromState => {
    if (
      !!newShopsFromState?.length &&
      route.query.storeId &&
      !queryParamLoaded.value
    ) {
      const selectedStore = newShopsFromState.find(
        store => store.id === (route.query.storeId as string)
      )
      if (selectedStore) {
        bookAnAppointmentForm.value.city = selectedStore.address.city
      }
    }
  })

  watch(
    () => bookAnAppointmentForm.value.city,
    newVal => {
      bookAnAppointmentForm.value.shop = ''

      const mapWithoutDuplicates = new Map<string, Option>()
      shopsFromCurrentState
        .value!.filter(shop => shop.address.city === newVal)
        .forEach(shop => {
          mapWithoutDuplicates.set(shop.c_siteID, {
            label: shop.c_internalName,
            value: shop.id!,
          })
        })
      shopsOptions.value = [...mapWithoutDuplicates.values()].sort((a, b) =>
        a.label.localeCompare(b.label)
      )
    }
  )

  if (route.query.countryIso) {
    bookAnAppointmentForm.value.country = route.query.countryIso as string
  } else {
    const currentState = countriesOptions.value?.filter(
      countryOption => countryOption.value === country.toUpperCase()
    )[0]
    if (currentState) bookAnAppointmentForm.value.country = currentState.value
  }

  if (
    bookAnAppointmentForm.value &&
    bookAnAppointmentForm.value.phonePrefix === ''
  ) {
    const countryPrefix = prefixes.find(prefix => {
      const prefixValueLowerCase = prefix.label.toLowerCase()
      return prefixValueLowerCase.includes(country)
    })

    if (countryPrefix)
      bookAnAppointmentForm.value.phonePrefix = countryPrefix.value
  }

  watch(
    () => shopsOptions.value,
    newShopsOptions => {
      if (
        shopsFromCurrentState.value &&
        route.query.storeId &&
        !queryParamLoaded.value
      ) {
        const selectedStore = shopsFromCurrentState?.value?.find(
          store => store?.id === (route?.query?.storeId as string)
        )

        if (selectedStore && selectedStore?.id) {
          nextTick(() => {
            bookAnAppointmentForm.value.shop = selectedStore?.id
          })
        }

        queryParamLoaded.value = true
      } else if (shopsFromCurrentState.value && newShopsOptions.length === 1) {
        nextTick(() => {
          bookAnAppointmentForm.value.shop = newShopsOptions?.[0]?.value
        })
      }
    }
  )

  return {
    bookAnAppointmentForm,
    countriesOptions,
    citiesOptions,
    shopsOptions,
    country,
    route,
    queryParamLoaded,
    bookAnAppointmentSelectedCountry,
    shopsFromCurrentState,
    prefixes,
    handleSubmitBookAnAppointment,
  }
}
